<template lang="pug">
.template-chooser-top-filter
  .row
    .flex-wrap.col-md-4.col-xl-2(v-for="option in options")
      .filter-block(@click="filterClick(option)")
        img(:src="require(`@/assets/admin/svg/selectorFilter/${option}.svg`)")
        om-heading.mt-2(h6 style="text-align: center") {{ $t(`templateChooser.filter.${option}`) }}
</template>

<script>
  import { track } from '@/services/xray';

  export default {
    data() {
      return {
        options: ['popup', 'gamification', 'nanobar', 'sidebar', 'embedded', 'imagePopup'],
      };
    },
    methods: {
      filterClick(option) {
        track(`templateSelector-${option}`);

        switch (option) {
          case 'pagePersonalization':
            this.$emit('pagePersonalizationClick');
            break;
          case 'pageABtest':
            this.$emit('pageABtestClick');
            break;
          case 'imagePopup':
            this.$emit('imagePopup');
            break;
          default:
            this.$emit('messageTypeFilter', option);
            break;
        }
      },
    },
  };
</script>

<style lang="sass">
  .template-chooser-top-filter
    margin-bottom: 48px
  .filter-block
    display: flex
    flex-direction: column
    align-items: center
    cursor: pointer
    margin-bottom: 12px

    img
      width: 100%
      max-width: 9.75rem
      aspect-ratio: 104 / 87
</style>
